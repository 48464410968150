<template>
  <section class="space-y-2">
    <h2 class="font-bold leading-none">Детали заказа</h2>

    <div class="space-y-1.5">
      <div class="flex justify-between gap-2">
        <div>Товаров на сумму</div>
        <div class="whitespace-nowrap">
          {{ money(cart.itemsCostWithoutDiscount) }}
        </div>
      </div>
      <div
        v-if="orderStore.selectedOrderType?.code === 'delivery'"
        class="flex justify-between gap-2"
      >
        <div>Доставка</div>
        <div class="whitespace-nowrap">{{ money(cart.deliveryCost || 0) }}</div>
      </div>
      <div class="flex justify-between gap-2">
        <div>Скидка</div>
        <div class="whitespace-nowrap">{{ money(cart.discount || 0) }}</div>
      </div>
      <div
        v-if="cart.bonusesEarned && authStore.isAuthenticated"
        class="flex justify-between gap-2"
      >
        <div>Вернётся бонусами</div>
        <div class="whitespace-nowrap">+ {{ cart.bonusesEarned }}</div>
      </div>
      <div v-if="cart.bonusesSpent" class="flex justify-between gap-2">
        <div>Списано бонусов</div>
        <div class="whitespace-nowrap">- {{ cart.bonusesSpent }}</div>
      </div>
      <div class="flex justify-between gap-2 font-bold">
        <div>Итого</div>
        <div class="whitespace-nowrap">{{ money(cart.total) }}</div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Cart } from '~/types/cart'

defineProps<{
  cart: Cart
}>()

const authStore = useAuthStore()
const orderStore = useOrderStore()
</script>
